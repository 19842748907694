import React, { useState, useEffect } from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import ComponentList from '../components/ComponentList'
import Carousel from '../components/Carousel';
import PullQuote from '../components/PullQuote';
import { SwitchTransition, Transition } from 'react-transition-group';

import cx from 'classnames';
import xss from 'xss';
import {
	isSameDay,
	parseISO,
	isAfter,
	intlFormat,
	isFuture,
	formatDistanceToNow,
} from 'date-fns';
import sanityImage from '../util/sanityImage';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import reduce from 'lodash/reduce';
import sanity from '../api/sanity';
import { allianceById } from '../api/queries';
import useHover from '../util/useHover';

const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const moneyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

const Alliance = ({ pageContext }) => {
	const {
		seo = {},
		id,
		artist,
		title,
		description,
		heroImage,
		artistInfo = {},
		content = [],
		charity = {},
		minimumBid,
		estimatedValue,
		postSubmit,
		terms,
		bids = [],
		sheetId,
		startDate,
		endDate,
	} = pageContext;

	const metaTitle = seo?.metaTitle || 'Library Street Collective';
	const openGraphTitle = seo?.openGraphTitle || 'Library Street Collective';
	const twitterTitle = seo?.twitterTitle || 'Library Street Collective';

	const [hoverRef, isHovered] = useHover();
	const [isExpired, setIsExpired] = useState(false);
	const [notStartedYet, setNotStartedYet] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
	const [formErrors, setFormErrors] = useState(null);
	const [isSubmitError, setIsSubmitError] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(null);
	const [isSubmitComplete, setIsSubmitComplete] = useState(null);
	const [topBid, setTopBid] = useState(0);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [location, setLocation] = useState('');
	const [bidAmount, setBidAmount] = useState(
		moneyFormatter.format(minimumBid)
	);

	const [bidList, setBidList] = useState([]);

	const phoneFormatter = phoneNumberString => {
		const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			const intlCode = match[1] ? '+1 ' : '';
			return [
				intlCode,
				'(',
				match[2],
				') ',
				match[3],
				'-',
				match[4],
			].join('');
		}
		return null;
	};

	const findTopBid = list => {
		const currentTopBid = reduce(
			list,
			(currentBid, index) => {
				if (index.bid < currentBid) return currentBid;
				else return index.bid;
			},
			0
		);

		setTopBid(
			moneyFormatter.format(
				currentTopBid === 0 ? minimumBid : currentTopBid
			)
		);
		setBidAmount(
			moneyFormatter.format(
				currentTopBid === 0 ? minimumBid : currentTopBid
			)
		);
	};

	useEffect(() => {
		const today = new Date();

		const now = intlFormat(today.getTime(), {
			hour: '2-digit',
			timeZone: 'America/New_York',
		});

		const parsedEndDate = parseISO(endDate);
		const parsedStartDate = parseISO(startDate);

		const hasStarted =
			isSameDay(today, parsedStartDate) ||
			isAfter(today, parsedStartDate);

		const isLastDay = isSameDay(today, parsedEndDate);

		const hasEnded = isAfter(today, parsedEndDate) && !isLastDay;

		const isLastDayAfterNine =
			isLastDay && now.includes('PM') && Number(now.split(' ')[0]) >= 9;

		const isOver = hasEnded || isLastDayAfterNine;

		setIsActive(hasStarted && isOver === false);
		setIsExpired(isOver);
		setNotStartedYet(hasStarted === false);

		const fetchBids = async () => {
			const { bids } = await sanity.fetch(allianceById(id));

			setBidList(bids);
			findTopBid(bids);
		};

		fetchBids();
	}, []);

	const handleSubmit = async event => {
		event.preventDefault();

		if (isExpired || notStartedYet) {
			return;
		}

		if (!firstName || !lastName || !phone || !email || !bidAmount) {
			setFormErrors(true);
			return;
		}
		setIsSubmitting(true);
		setFormErrors(false);

		const formattedBid = parseFloat(bidAmount.replace(/[$,]/g, ''));

		try {
			const request = await fetch('/.netlify/functions/auction', {
				method: 'POST',
				body: JSON.stringify({
					name: `${xss(firstName)} ${xss(lastName)}`,
					phone: xss(phone),
					email: xss(email),
					location: xss(location),
					bid: formattedBid,
					id,
					sheetId,
				}),
			});

			const response = await request.json();

			if (response.success) {
				findTopBid(response.bids);
				setIsSubmitComplete(true);
				setIsSubmitting(false);
				setIsSubmitError(false);
			}
		} catch (error) {
			console.error(error);
			setIsSubmitError(true);
		}
	};

	const heroSection = (
		<Reveal>
			<section className="reveal__slide reveal__delay--1">
				<picture
					ref={hoverRef}
					className="db x bb--black artwork--full-container"
				>
					<source
						srcSet={sanityImage(heroImage.url, { w: 2400 })}
						media="(min-width: 1800px)"
					/>
					<source
						srcSet={sanityImage(heroImage.url, { w: 2000 })}
						media="(min-width: 1600px)"
					/>
					<source
						srcSet={sanityImage(heroImage.url, { w: 1600 })}
						media="(min-width: 1200px)"
					/>
					<source
						srcSet={sanityImage(heroImage.url, { w: 1200 })}
						media="(min-width: 800px)"
					/>
					<source
						srcSet={sanityImage(heroImage.url, { w: 1000 })}
						media="(min-width: 600px)"
					/>
					<img
						alt={artist || title || 'Library Street Collective'}
						className={cx('db x y artwork--full', {
							hovered: isHovered,
						})}
						src={sanityImage(heroImage.url, { w: 800 })}
					/>
				</picture>
				<aside className="mt2 grid-container contained">
					<h1 className="sans--24 sans--36--md sans--48--lg">
						{title}
					</h1>
					{title !== artist && (
						<p className="sans--18 sans--24--md sans--36--lg">
							{artist}
						</p>
					)}
				</aside>

				{description && (
					<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
						<div className="row align--center">
							<div className="col c10--md c9--lg c7--xl">
								<div className="sans--18 sans--24--md line-break rich-text ">
									<BlockContent
										blocks={description}
										serializers={richText}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</section>
		</Reveal>
	);

	const biddingForm = !isExpired && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg grid-container contained">
				<SwitchTransition>
					<Transition
						key={isSubmitComplete}
						mountOnEnter
						unmountOnExit
						appear
						timeout={TRANSITION_DURATION}
					>
						{status => (
							<React.Fragment>
								{isSubmitComplete && postSubmit && (
									<div
										className="row align--center"
										style={{
											...TRANSITION_STYLES.default,
											...TRANSITION_STYLES[status],
										}}
									>
										<div className="col c10--lg c8--xl tile--form tile--large p2 pt5--lg pb5--lg">
											<div className="sans--18 sans--24--md line-break">
												<BlockContent
													blocks={postSubmit}
													serializers={richText}
												/>
											</div>
										</div>
									</div>
								)}
								{(!isSubmitComplete || !postSubmit) && (
									<div
										className="row align--center"
										style={{
											...TRANSITION_STYLES.default,
											...TRANSITION_STYLES[status],
										}}
									>
										<form
											className={cx(
												'col c10--lg c8--xl tile--form tile--large p2 pt5--lg pb5--lg',
												{
													disabled:
														isExpired ||
														notStartedYet,
													isSubmitting,
												}
											)}
											onSubmit={handleSubmit}
										>
											<div className="df jcb mb5 mb0--md">
												<p className="sans--18 sans--24--md sans--36--lg">
													{isActive &&
														'Place your Bid'}
													{notStartedYet &&
														`Auction starts in ${formatDistanceToNow(
															parseISO(startDate)
														)}`}
													{isExpired &&
														`Auction ended ${formatDistanceToNow(
															parseISO(endDate)
														)}`}
												</p>
												<div className="tr">
													{isActive && (
														<p className="sans--18 sans--24--md ">
															{!bidList
																? 'Minimum Bid'
																: 'Current Top Bid'}
															:
															<br />
															{topBid
																? topBid
																: 'No Bids Yet'}
														</p>
													)}
													{estimatedValue && (
														<p className="mt1 mt2--md sans--14 sans--18--md color--grey">
															Estimated value:{' '}
															{estimatedValue}
														</p>
													)}
												</div>
											</div>
											<div className="mt2 row">
												<div className="col c6--md">
													<label
														className="input__label db"
														htmlFor="firstName"
													>
														<input
															className="input__input db x y p1 sans--18"
															type="text"
															id="firstName"
															name="firstName"
															placeholder="First Name"
															onChange={event => {
																const {
																	target,
																} = event;
																setFirstName(
																	target.value
																);
															}}
															disabled={
																isExpired ||
																notStartedYet
															}
														/>
													</label>
												</div>
												<div className="col c6--md mt2 mt0--md">
													<label
														className="input__label db"
														htmlFor="lastName"
													>
														<input
															className="input__input db x y p1 sans--18"
															type="text"
															id="lastName"
															name="lastName"
															placeholder="Last Name"
															onChange={event => {
																const {
																	target,
																} = event;
																setLastName(
																	target.value
																);
															}}
															disabled={
																isExpired ||
																notStartedYet
															}
														/>
													</label>
												</div>
											</div>
											<div className="mt2 row">
												<div className="col c6--md">
													<label
														className="input__label db"
														htmlFor="phone"
													>
														<input
															className="input__input db x y p1 sans--18"
															type="tel"
															id="phone"
															name="phone"
															placeholder="Phone"
															onChange={event => {
																const {
																	target,
																} = event;
																setPhone(
																	target.value
																);
															}}
															value={phoneFormatter(
																phone
															)}
															disabled={
																isExpired ||
																notStartedYet
															}
														/>
													</label>
												</div>
												<div className="col c6--md mt2 mt0--md">
													<label
														className="input__label db"
														htmlFor="email"
													>
														<input
															className="input__input db x y p1 sans--18"
															type="email"
															id="email"
															name="email"
															placeholder="Email"
															onChange={event => {
																const {
																	target,
																} = event;
																setEmail(
																	target.value
																);
															}}
															disabled={
																isExpired ||
																notStartedYet
															}
														/>
													</label>
												</div>
											</div>
											<div className="mt2 row">
												<div className="col c6--md">
													<label
														className="input__label db"
														htmlFor="location"
													>
														<input
															className="input__input db x y p1 sans--18"
															type="text"
															id="location"
															name="location"
															placeholder="Location"
															onChange={event => {
																const {
																	target,
																} = event;
																setLocation(
																	target.value
																);
															}}
															disabled={
																isExpired ||
																notStartedYet
															}
														/>
													</label>
												</div>
												<div className="col c6--md mt2 mt0--md">
													<label
														className="input__label db"
														htmlFor="bidAmount"
													>
														<input
															className="input__input db x y p1 sans--18"
															type="text"
															id="bidAmount"
															name="bidAmount"
															placeholder={`Minimum bid ${bidAmount}`}
															onChange={e => {
																const {
																	target,
																} = e;
																setBidAmount(
																	target.value
																);
															}}
															disabled={
																isExpired ||
																notStartedYet
															}
														/>
													</label>
												</div>
											</div>
											<div className="row align--right mt2">
												<div className="col c6 ">
													<div className="tr">
														<button
															disabled={
																isExpired ||
																notStartedYet ||
																(terms &&
																	!hasAcceptedTerms)
															}
															className="sans--18"
														>
															Submit
														</button>
													</div>
												</div>
											</div>
											{formErrors && (
												<p className="mt2 error sans--14 sans--18--md">
													Please fill out the form
												</p>
											)}
											{isSubmitError && (
												<p className="mt2 error sans--14 sans--18--md">
													Something went wrong. Please
													try again shortly.
												</p>
											)}
											{isSubmitComplete && (
												<p className="mt2 sans--14 sans--18--md">
													Thank you for your bid!
												</p>
											)}
											{terms && (
												<div className="mt2">
													<p className="sans--18 sans--24--lg">
														Terms *
													</p>
													<div className="rich-text line-break sans--14 sans--18--md mt1 mt2 color--grey">
														<BlockContent
															blocks={terms}
															serializers={
																richText
															}
														/>
													</div>
													<label className="checkbox__container mt1">
														<input
															type="checkbox"
															className="checkbox__input"
															onChange={e => {
																const {
																	target,
																} = e;
																setHasAcceptedTerms(
																	target.checked
																);
															}}
														/>
														<span className="checkbox__span" />
													</label>
												</div>
											)}
										</form>
									</div>
								)}
							</React.Fragment>
						)}
					</Transition>
				</SwitchTransition>
			</div>
		</Reveal>
	);

	const allianceContent = content && content.length > 0 && (
		<Reveal className="mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg">
			<div className="reveal__slide reveal__delay--1">
				<ComponentList components={content} />
			</div>
		</Reveal>
	);

	const charitySection = charity && (
		<Reveal className="mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg ">
			<div className="reveal__slide reveal__delay--1 ">
				<p className="sans--24 sans--36--md sans--48--lg mb5 mb0--md grid-container contained">
					<a href={charity.url} className="link--underline-reverse">
						{charity.title}
					</a>
				</p>

				<div className="mt5 mt0--md df fw row grid-container contained">
					<figure className="col c5--lg psy--lg top pt10--lg">
						{charity.images && charity.images.length > 1 && (
							<Carousel list={charity.images} />
						)}
						{charity.images && charity.images.length === 1 && (
							<picture>
								<source
									srcSet={`${charity.images[0].url}?w=2000&auto=format&q=100`}
									media="(min-width: 1200px)"
								/>
								<source
									srcSet={`${charity.images[0].url}?w=1200&auto=format&q=100`}
									media="(min-width: 1000px)"
								/>
								<source
									srcSet={`${charity.images[0].url}?w=800&auto=format`}
									media="(min-width: 600px)"
								/>
								<img
									alt={
										charity.title ||
										'Library Street Collective'
									}
									className="db x b--black"
									src={`${charity.images[0].url}?w=600&auto=format`}
								/>
							</picture>
						)}
					</figure>
					<div className="col c1--lg show--lg pt5--md pt10--lg" />
					{charity.body && (
						<div className="col c6--md mt2 mt0--md--lg pt5 pt10--lg">
							<div className="sans--18 sans--24--md line-break rich-text ">
								<BlockContent
									blocks={charity.body}
									serializers={richText}
								/>
							</div>
						</div>
					)}
				</div>
				{charity.pullQuote && <PullQuote {...charity.pullQuote} />}
			</div>
		</Reveal>
	);

	const artistSection = artist && artistInfo && (
		<Reveal className="mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg">
			<div className="reveal__slide reveal__delay--1 grid-container contained">
				<p className="sans--24 sans--36--md sans--48--lg mb5 mb0--md">
					{artist}
				</p>

				<div className="mt5 mt0--md df fw row">
					<figure className="col c5--lg psy--lg top pt10--lg">
						<picture>
							<source
								srcSet={`${artistInfo.artistImage.url}?w=2000&auto=format&q=100`}
								media="(min-width: 1200px)"
							/>
							<source
								srcSet={`${artistInfo.artistImage.url}?w=1200&auto=format&q=100`}
								media="(min-width: 1000px)"
							/>
							<source
								srcSet={`${artistInfo.artistImage.url}?w=800&auto=format`}
								media="(min-width: 600px)"
							/>
							<img
								alt={artist}
								className="db x b--black"
								src={`${artistInfo.artistImage.url}?w=600&auto=format`}
							/>
						</picture>
						{artistInfo.artistImage.credit && (
							<figcaption className="sans--12 sans--14--lg mt1">
								{artistInfo.artistImage.credit}
							</figcaption>
						)}
					</figure>
					<div className="col c1--lg show--lg pt5--md pt10--lg" />
					<div className="col c6--md mt2 mt0--md--lg pt5 pt10--lg">
						<div className="sans--18 sans--24--md line-break rich-text ">
							<BlockContent
								blocks={artistInfo.artistBio}
								serializers={richText}
							/>
						</div>
					</div>
				</div>
			</div>
		</Reveal>
	);

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage || seo?.twitterImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.openGraphImage || seo?.twitterImage}
			/>

			{heroSection}
			{biddingForm}
			{allianceContent}
			{charitySection}
			{artistSection}
		</React.Fragment>
	);
};

export default Alliance;
