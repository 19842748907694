import React from 'react';
import sanityImage from '../util/sanityImage';
import { ArrowRight } from './SVG';

import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

const Carousel = ({ list = [] }) => {
	const [sliderRef, slider] = useKeenSlider({
		slidesPerView: 1,
		loop: true,
		spacing: 50,
	});
	return (
		<div className="pr grid-container contained">
			<div ref={sliderRef} className="keen-slider featured__slider df ">
				{list.map(img => {
					return (
						<div
							key={img._key}
							className="keen-slider__slide carousel__slide"
						>
							<picture>
								<source
									srcSet={sanityImage(img.url, {
										w: 1200,
									})}
									media="(min-width: 600px)"
								/>
								<img
									alt={
										img?.alt || 'Library Street Collective'
									}
									className="db x b--black"
									src={sanityImage(img.url, {
										w: 1200,
									})}
								/>
							</picture>
							{img.credit && (
								<p className="sans--12 sans--14--lg mt1">
									{img.credit}
								</p>
							)}
						</div>
					);
				})}
			</div>
			{slider && (
				<React.Fragment>
					<button
						className="slider__button slider__button--prev pa"
						onClick={e => e.stopPropagation() || slider.prev()}
					>
						<ArrowRight className="icon icon--vertical icon--slider flip" />
					</button>
					<button
						className="slider__button slider__button--next pa"
						onClick={e => e.stopPropagation() || slider.next()}
					>
						<ArrowRight className="icon icon--vertical icon--slider" />
					</button>
				</React.Fragment>
			)}
		</div>
	);
};

export default Carousel;
