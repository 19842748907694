const groq = require('groq');
//
// === Drafts ===
//

module.exports.draftDocument = draftId => groq`*[_id == '${draftId}'][0] {...}`;

//
// === Components ===
//

const link = groq`{
	_key,
	_type,
	type,
	openInNewWindow,
	title,
	url,
}`;

const file = groq`{
	'url': asset->url,
    'originalFilename': asset->originalFilename,
    'title': title
}`;

const externalVideo = groq`{
	videoID,
	autoPlay,
	loop
}`;

const pullQuote = groq`{
	quote,
	source
}`;

const asset = groq`{
	_type,
	_key,
	alt,
	credit,
	'dimensions': asset->metadata.dimensions,
	'url': asset->url,
}`;

const richText = groq`[] {
	...,
	'asset': asset->url,
}`;

const artworkAsset = groq`{
	_type,
	_key,
	title,
	portrait,
	description ${richText},
	artwork ${asset}
}`;

const seo = groq`{
	metaTitle,
	metaDescription,
	metaKeywords,
	openGraphTitle,
	openGraphDescription,
	openGraphImage ${asset},
	twitterTitle,
	twitterDescription,
	twitterImage ${asset},
}`;

const email = groq`{
	linkText,
	subject,
	body
}`;

const components = groq`components[] {
	_key,
    _type,
	...,

    _type == 'bigText' => {
    	description ${richText}
  	},

    _type == 'featuredLinkSingle' => {
			multiLinkDesc ${richText},
    	'customImage': exhibition.image.asset->url,
		'invert': exhibition.invert,
    	'slug': exhibition.link->slug.current,
		'pageSlug': exhibition.link->url,
    	'linkType': exhibition.link->_type,
    	'title': exhibition.link->title,
		'tileTitle': exhibition.link->tileTitle,
    	'artist': exhibition.link->artist,
    	'exhibitionLocation': exhibition.link->exhibitionLocation,
    	'exhibitionLocationOther': exhibition.link->exhibitionLocationOther,
    	'hero': exhibition.link->hero.asset->url,
    	'image': exhibition.link->image.asset->url,
    	'heroImage': exhibition.link->heroImage.asset->url,
    	'subtitle': exhibition.link->subtitle,
		'startDate': exhibition.link->startDate,
		'endDate': exhibition.link->endDate,
  	},

    _type == 'featuredLinkFullWidth' => {
			multiLinkDesc ${richText},
    	'customImage': exhibition.image.asset->url,
		'invert': exhibition.invert,
    	'slug': exhibition.link->slug.current,
		'pageSlug': exhibition.link->url,
    	'linkType': exhibition.link->_type,
    	'title': exhibition.link->title,
		'tileTitle': exhibition.link->tileTitle,
    	'artist': exhibition.link->artist,
    	'exhibitionLocation': exhibition.link->exhibitionLocation,
    	'exhibitionLocationOther': exhibition.link->exhibitionLocationOther,
    	'hero': exhibition.link->hero.asset->url,
    	'image': exhibition.link->image.asset->url,
    	'heroImage': exhibition.link->heroImage.asset->url,
    	'subtitle': exhibition.link->subtitle,
		'startDate': exhibition.link->startDate,
		'endDate': exhibition.link->endDate,
  	},

	_type == 'featuredLinkMulti' => {
		title,
		list[] {
			_key,
			size,
			multiLinkDesc ${richText},
			'startDate': link.link->startDate,
			'endDate': link.link->endDate,
			'invert': link.invert,
			'customImage': link.image.asset->url,
			'slug': link.link->slug.current,
			'pageSlug': link.link->url,
			'linkType': link.link->_type,
			'title': link.link->title,
			'tileTitle': link.link->tileTitle,
			'artist': link.link->artist,
    			'exhibitionLocation': link.link->exhibitionLocation,
    			'exhibitionLocationOther': link.link->exhibitionLocationOther,
			'hero': link.link->hero.asset->url,
			'image': link.link->image.asset->url,
			'heroImage': link.link->heroImage.asset->url,
			'subtitle': link.link->subtitle,
		}
	},

	_type == 'richTextPage' => {
		content ${richText}
	},

	_type == 'locationInfoPage' => {
		pageLocationDetails ${richText},
		pageLocationContent ${richText}
	},

	_type == 'contactListPage' => {
		'contacts': list[] {
			_key,
			name,
			position,
			email
		}
	},

	_type == 'audioPlayer' => {
		title,
		audio ${file},
	},

	_type == 'pullQuote' => ${pullQuote},

	_type == 'sectionTitle' => {
		title
	},

	_type == 'sectionCover' => {
		image ${asset}
	},

	_type == 'imageGrid' => {
		artwork [] {
			_key,
			_type,
			_type == 'artworkImageSingle' => {
				title,
				description ${richText},
				portrait,
				artwork ${asset}
			},
			_type == 'artworkImageDouble' => {
				imageOne ${artworkAsset},
				imageTwo ${artworkAsset},
			}
		}
	},
}`;

//
// === Globals ===
//

module.exports.header = groq`*[_type == 'header'][0]{
	...,
}`;

module.exports.footer = groq`*[_type == 'footer'][0] {
	...,
	address ${richText},
	addressTwo ${richText},
	phone,
	email,
	links[] ${link},
	newsletterCTA,
	newsletterID,
	newsletterCopy ${richText},
}`;

//
// === Homepage ===
//

module.exports.homepage = groq`*[_type == 'homepage'][0] {
	${components},
	seo ${seo},
}`;

module.exports.draftHomepage = draftId => groq`*[_type == 'homepage' && _id == '${draftId}'][0] {
	${components},
	seo ${seo},
}`;

//
// === About ===
//
module.exports.aboutpage = groq`*[_type == 'aboutpage'][0] {
	gallery [] ${asset},
	description  ${richText},
	info  ${richText},
	'contacts': list[] {
		_key,
		name,
		position,
		email
	},
	seo ${seo},
}`;

module.exports.draftAboutpage = draftId => groq`*[_type == 'aboutpage' && _id == '${draftId}'][0] {
	gallery [] ${asset},
	description  ${richText},
	info  ${richText},
	'contacts': list[] {
		_key,
		name,
		position,
		email
	},
	seo ${seo},
}
`;

//
// === Pages ===
//

module.exports.pages = groq`*[_type in ['page']] {
	title,
	url,
	tileTitle,
	image ${asset},
	${components},
	seo ${seo},
}`;

module.exports.draftPage = draftId => groq`*[_type == 'page' && _id == '${draftId}'][0]{
	title,
	url,
	tileTitle,
	image ${asset},
	${components},
	seo ${seo},
}`;

//
// === Exhibitions ===
//

module.exports.exhibitionspage = groq`*[_type == 'exhibitionpage'][0] {
	featuredExhibitionsTitle,
	featuredExhibitions[] -> {
		_type,
		_key,
		'linkType': _type,
		'slug': slug.current,
		title,
		tileTitle,
		artist,
 		exhibitionLocation,
 		exhibitionLocationOther,
		subtitle,
		startDate,
		endDate,
		image ${asset},
		hero ${asset}
	},
	listTitle,
	list[] -> {
		_type,
		_key,
		'linkType': _type,
		'slug': slug.current,
		title,
		tileTitle,
		artist,
 		exhibitionLocation,
 		exhibitionLocationOther,
		subtitle,
		startDate,
		endDate,
		image ${asset},
		hero ${asset}
	},
	upcomingListTitle,
	upcomingList[] -> {
		_type,
		_key,
		'linkType': _type,
		'slug': slug.current,
		title,
		tileTitle,
		artist,
 		exhibitionLocation,
 		exhibitionLocationOther,
		subtitle,
		startDate,
		endDate,
		image ${asset},
		hero ${asset}
	},
	pastListTitle,
	pastList[] -> {
		_type,
		_key,
		'linkType': _type,
		'slug': slug.current,
		title,
		tileTitle,
		artist,
 		exhibitionLocation,
 		exhibitionLocationOther,
		subtitle,
		startDate,
		endDate,
		image ${asset},
		hero ${asset}
	},
	siteDescription ${richText},
	anatomyDescription ${richText},
	queueDescription ${richText},
	seo ${seo}
}`;

module.exports.draftExhibitionsPage = draftId => groq`*[_type == 'exhibitionpage' && _id == '${draftId}'][0]{
	featuredExhibitionsTitle,
	featuredExhibitions[] -> {
		_type,
		_key,
		'linkType': _type,
		'slug': slug.current,
		title,
		tileTitle,
		artist,
 		exhibitionLocation,
 		exhibitionLocationOther,
		subtitle,
		image ${asset},
		hero ${asset}
	},
	listTitle,
	list[] -> {
		_type,
		_key,
		'linkType': _type,
		'slug': slug.current,
		title,
		tileTitle,
		artist,
 		exhibitionLocation,
 		exhibitionLocationOther,
		subtitle,
		image ${asset},
		hero ${asset}
	},
	upcomingListTitle,
	upcomingList[] -> {
		_type,
		_key,
		'linkType': _type,
		'slug': slug.current,
		title,
		tileTitle,
		artist,
 		exhibitionLocation,
 		exhibitionLocationOther,
		subtitle,
		image ${asset},
		hero ${asset}
	},
	pastListTitle,
	pastList[] -> {
		_type,
		_key,
		'linkType': _type,
		'slug': slug.current,
		title,
		tileTitle,
		artist,
 		exhibitionLocation,
 		exhibitionLocationOther,
		subtitle,
		image ${asset},
		hero ${asset}
	},
	siteDescription ${richText},
	anatomyDescription ${richText},
	queueDescription ${richText},
}`;

module.exports.exhibitions = groq`*[_type == 'exhibition' && !(_id in path('drafts.**'))][] | order(priority desc, _createdAt desc) {
	_id,
	'id': _id,
	_type,
	'linkType' : _type,
	'slug': slug.current,
	title,
	artist,
 	exhibitionLocation,
 		exhibitionLocationOther,
	subtitle,
	type,
	startDate,
	endDate,
	'hero': image ${asset},
	virtualTourCode,
	pressReleaseTitle,
  	pressReleaseImg ${artworkAsset},
	pressReleaseFile ${file},
	pressReleaseContent ${richText},
	video ${externalVideo},
	installation[] ${asset},
	artworkTitle,
	artwork [] {
		_key,
		_type == 'artworkImageSingle' => ${artworkAsset},
		_type == 'artworkImageDouble' => {
			imageOne ${artworkAsset},
			imageTwo ${artworkAsset},
		}
	},
	recommendedProjectsTitle,
	recommendedProjects[] {
		_key,
		_type,
		'linkType': _type,
		'title': @->title,
		'tileTitle': @->tileTitle,
		'subtitle': @->subtitle,
		'artist': @->artist,
		'slug': @->slug.current,
		'startDate': @->startDate,
		'endDate': @->endDate,
		'hero': @->hero ${asset},
		'heroImage': @->heroImage ${asset},
		'image': @->image ${asset}
	},
	inquire ${email},
	seo ${seo},
}`;

module.exports.draftExhibition = draftId => groq`*[_type == 'exhibition' && _id == '${draftId}'][0]{
	_id,
	'id': _id,
	_type,
	'linkType' : _type,
	'slug': slug.current,
	title,
	artist,
 	exhibitionLocation,
 	exhibitionLocationOther,
	subtitle,
	type,
	startDate,
	endDate,
	'hero': image ${asset},
	virtualTourCode,
	pressReleaseTitle,
  	pressReleaseImg ${artworkAsset},
	pressReleaseFile ${file},
	pressReleaseContent ${richText},
	video ${externalVideo},
	installation[] ${asset},
	artworkTitle,
	artwork [] {
		_key,
		_type == 'artworkImageSingle' => ${artworkAsset},
		_type == 'artworkImageDouble' => {
			imageOne ${artworkAsset},
			imageTwo ${artworkAsset},
		}
	},
	recommendedProjectsTitle,
	recommendedProjects[] {
		_key,
		_type,
		'linkType': _type,
		'title': @->title,
		'tileTitle': @->tileTitle,
		'subtitle': @->subtitle,
		'artist': @->artist,
		'slug': @->slug.current,
		'startDate': @->startDate,
		'endDate': @->endDate,
		'hero': @->hero ${asset},
		'heroImage': @->heroImage ${asset},
		'image': @->image ${asset}
	},
	inquire ${email},
	seo ${seo},
}`;

//
// === Anatomy ===
//

module.exports.anatomies = groq`*[_type == 'anatomy' && !(_id in path('drafts.**'))][] | order(priority desc, _createdAt desc) {
	_id,
	'id': _id,
	title,
	subtitle,
	'slug': slug.current,
	hero ${asset},
	heroGallery [] ${asset},
	intro ${richText},
	backgroundTitle,
	backgroundImage ${asset},
  	backgroundGallery[] ${artworkAsset},
	backgroundText ${richText},
	backgroundVideo ${externalVideo},
	inspirationTitle,
	inspirationCover ${asset},
	inpirationSection {
		audio ${file},
		description ${richText},
		image ${artworkAsset}
	},
	inspirationQuote ${pullQuote},
	inspirationGallery[] ${artworkAsset},
	artworksTitle,
	artworkSectionDescription ${richText},
	artworkSectionVideo ${externalVideo},
	artworkSections[] {
		_key,
		title,
		cover ${asset},
		content [] {
			...,
			_key,
			_type,
			_type == 'richText' => {
				content ${richText},
			},
			_type == 'pullQuote' => ${pullQuote},
			_type == 'fullWidthImage' => ${asset},
			_type == 'artworkImageSingle' => ${artworkAsset},
			_type == 'artworkDouble' => {
				imageOne ${artworkAsset},
				imageTwo ${artworkAsset},
			},
			_type == 'multimediaSection' => {
				image ${artworkAsset},
				detailShot ${artworkAsset},
				audio ${file},
				description ${richText}
			},
			_type == 'imageAndAudio' => {
				image ${artworkAsset},
				audio ${file},
			},
		},
	},
	conversationsTitle,
	conversationsCover ${asset},
	conversationSections[] {
		_key,
		name,
		image ${asset},
		description ${richText},
		content[] {
			_key,
			_type,
			_type == 'externalVideo' => ${externalVideo},
			_type == 'pullQuote' => ${pullQuote},
			_type == 'richText' => {
				content ${richText},
			},
			_type == 'imageAndAudio' => {
				image ${artworkAsset},
				audio ${file},
			},
		}
	},
	studioVisit[] {
		_key,
		_type,
		_type == 'fullWidthImage' => ${asset},
		_type == 'pullQuote' => ${pullQuote},
		_type == 'studioGallery' => {
			list[] ${asset}
		},
		_type == 'spotify' => {
			embed,
			description ${richText}
		}

	},
	inquire ${email},
	seo ${seo},
}`;

module.exports.draftAnatomy = draftId => groq`*[_type == 'anatomy' && _id == '${draftId}'][0]{
	_id,
	'id': _id,
	title,
	subtitle,
	'slug': slug.current,
	hero ${asset},
	heroGallery [] ${asset},
	intro ${richText},
	backgroundTitle,
	backgroundImage ${asset},
  	backgroundGallery[] ${artworkAsset},
	backgroundVideo ${externalVideo},
	backgroundText ${richText},
	inspirationTitle,
	inspirationCover ${asset},
	inpirationSection {
		audio ${file},
		description ${richText},
		image ${artworkAsset}
	},
	inspirationQuote ${pullQuote},
	inspirationGallery[] ${artworkAsset},
	artworksTitle,
	artworkSectionDescription ${richText},
	artworkSectionVideo ${externalVideo},
	artworkSections[] {
		_key,
		title,
		cover ${asset},
		content [] {
			...,
			_key,
			_type,
			_type == 'richText' => {
				content ${richText},
			},
			_type == 'pullQuote' => ${pullQuote},
			_type == 'fullWidthImage' => ${asset},
			_type == 'artworkImageSingle' => ${artworkAsset},
			_type == 'artworkDouble' => {
				imageOne ${artworkAsset},
				imageTwo ${artworkAsset},
			},
			_type == 'multimediaSection' => {
				image ${artworkAsset},
				detailShot ${artworkAsset},
				audio ${file},
				description ${richText}
			},
			_type == 'imageAndAudio' => {
				image ${artworkAsset},
				audio ${file},
			},
		},
	},
	conversationsTitle,
	conversationsCover ${asset},
	conversationSections[] {
		_key,
		name,
		image ${asset},
		description ${richText},
		content[] {
			_key,
			_type,
			_type == 'externalVideo' => ${externalVideo},
			_type == 'pullQuote' => ${pullQuote},
			_type == 'richText' => {
				content ${richText},
			},
			_type == 'imageAndAudio' => {
				image ${artworkAsset},
				audio ${file},
			},
		}
	},
	studioVisit[] {
		_key,
		_type,
		_type == 'fullWidthImage' => ${asset},
		_type == 'pullQuote' => ${pullQuote},
		_type == 'studioGallery' => {
			list[] ${asset}
		},
		_type == 'spotify' => {
			embed,
			description ${richText}
		}

	},
	inquire ${email},
	seo ${seo},
}`;

//
// === SITE ===
//

module.exports.sites = groq`*[_type == 'site' && !(_id in path('drafts.**'))][] | order(priority desc, _createdAt desc) {
	_id,
	'id': _id,
	title,
	subtitle,
	'slug': slug.current,
	hero ${asset},
	heroGallery[] ${asset},
	intro ${richText},
	locationCover ${asset},
	locationDescription[] {
		_key,
		_type,
		_type == 'locationIntro' => {
			'intro': content
		},
		_type == 'locationSection' => {
			image ${artworkAsset},
			gallery [] ${artworkAsset},
			description ${richText}
		}
	},
	installationImages [] ${asset},
	artistsIntroText ${richText},
	artists[] {
		_key,
		_type,
		title,
		cover ${asset},
		coverVideo ${externalVideo},
		portrait ${asset},
		portraitCaption,
		description ${richText},
		introText ${richText},
		content[] {
			_key,
			_type,
			_type == 'audioPlayer' => {
				title,
				audio ${file}
			},
			_type == 'externalVideo' => ${externalVideo},
			_type == 'pullQuote' => ${pullQuote},
			_type == 'grid' => {
				artwork [] {
					_key,
					_type,
					_type == 'selectedArtworkSingle' => {
						title,
						description ${richText},
						portrait,
						artwork ${asset}
					},
					_type == 'selectedArtworkDouble' => {
						imageOne ${artworkAsset},
						imageTwo ${artworkAsset},
					}
				}
			}
		},
	},
	inquire ${email},
	seo ${seo}
}`;

module.exports.draftSite = draftId => groq`*[_type == 'site' && _id == '${draftId}'][0]{
	_id,
	'id': _id,
	title,
	subtitle,
	'slug': slug.current,
	hero ${asset},
	heroGallery[] ${asset},
	intro ${richText},
	locationCover ${asset},
	locationDescription[] {
		_key,
		_type,
		_type == 'locationIntro' => {
			'intro': content
		},
		_type == 'locationSection' => {
			image ${artworkAsset},
			gallery [] ${artworkAsset},
			description ${richText}
		}
	},
	installationImages [] ${asset},
	artistsIntroText ${richText},
	artists[] {
		_key,
		_type,
		title,
		cover ${asset},
		coverVideo ${externalVideo},
		portrait ${asset},
		portraitCaption,
		description ${richText},
		introText ${richText},
		content[] {
			_key,
			_type,
			_type == 'audioPlayer' => {
				title,
				audio ${file}
			},
			_type == 'externalVideo' => ${externalVideo},
			_type == 'pullQuote' => ${pullQuote},
			_type == 'grid' => {
				artwork [] {
					_key,
					_type,
					_type == 'selectedArtworkSingle' => {
						title,
						description ${richText},
						portrait,
						artwork ${asset}
					},
					_type == 'selectedArtworkDouble' => {
						imageOne ${artworkAsset},
						imageTwo ${artworkAsset},
					}
				}
			}
		},
	},
	inquire ${email},
	seo ${seo}
}`;

//
// === QUEUE ===
//

module.exports.queues = groq`*[_type == 'queue' && !(_id in path('drafts.**'))][] | order(priority desc, _createdAt desc) {
	_id,
	'id': _id,
	title,
	subtitle,
	startDate,
	endDate,
	'color': color.hex,
	'slug': slug.current,
	hero ${asset},
	queueHero ${asset},
	description ${richText},
	episodesTitle,
	episodesImage ${asset},
	list[] {
		_key,
		videoID,
		title,
		lockedText ${richText},
		description ${richText},
	},
	artworkTitle,
	artworkImage ${asset},
	content[] {
		_key,
		_type,
		_type == 'selectedArtworkDouble' => {
			imageOne ${artworkAsset},
			imageTwo ${artworkAsset},
		},
		_type == 'selectedArtworkSingle' => {
			title,
			portrait,
			description ${richText},
			artwork ${asset}
		},
		_type == 'pullQuote' => ${pullQuote},
		_type == 'richText' => {
			description ${richText}
		},

	},
	inquire ${email},
	seo ${seo}
}`;

module.exports.draftQueue = draftId => groq`*[_type == 'queue' && _id == '${draftId}'][0]{
	_id,
	'id': _id,
	title,
	subtitle,
	startDate,
	endDate,
	'color': color.hex,
	'slug': slug.current,
	hero ${asset},
	queueHero ${asset},
	description ${richText},
	episodesTitle,
	episodesImage ${asset},
	list[] {
		_key,
		videoID,
		title,
		lockedText ${richText},
		description ${richText},
	},
	artworkTitle,
	artworkImage ${asset},
	content[] {
		_key,
		_type,
		_type == 'artworkImageDouble' => {
			imageOne ${artworkAsset},
			imageTwo ${artworkAsset},
		},
		_type == 'selectedArtworkSingle' => {
			title,
			portrait,
			description ${richText},
			artwork ${asset}
		},
		_type == 'pullQuote' => ${pullQuote},
		_type == 'pullQuote' => {
			description ${richText}
		},

	},
	inquire ${email},
	seo ${seo}
}`;

//
// === Projects ===
//

module.exports.projects = groq`*[
	_type == 'project' &&
	!(_id in path('drafts.**'))
][] | order(priority desc, _createdAt desc) {
	_id,
	'id': _id,
	'slug': slug.current,
	title,
	artist,
	'type': type->type,
	'hero': heroImage ${asset},
	heroGallery[] ${asset},
	description ${richText},
	video ${externalVideo},
	projectGalleryTitle,
	projectGallery[] ${artworkAsset},
	recommendedProjectsTitle,
	recommendedProjects[] {
		_key,
		_type,
		'linkType': @->_type,
		'title': @->title,
		'tileTitle': @->tileTitle,
		'subtitle': @->subtitle,
		'artist': @->artist,
		'pageSlug': @->url,
		'slug': @->slug.current,
		'startDate': @->startDate,
		'endDate': @->endDate,
		'hero': @->hero ${asset},
		'heroImage': @->heroImage ${asset},
		'image': @->image ${asset}
	},
	seo ${seo}
}`;

module.exports.draftProject = draftId => groq`*[_type == 'project' && _id == '${draftId}'][0]{
	_id,
	'id': _id,
	'slug': slug.current,
	title,
	artist,
	'type': type->type,
	'hero': heroImage ${asset},
	heroGallery[] ${asset},
	description ${richText},
	video ${externalVideo},
	projectGalleryTitle,
	projectGallery[] ${artworkAsset},
	recommendedProjectsTitle,
	recommendedProjects[] {
		_key,
		_type,
		'linkType': @->_type,
		'title': @->title,
		'tileTitle': @->tileTitle,
		'subtitle': @->subtitle,
		'artist': @->artist,
		'pageSlug': @->url,
		'slug': @->slug.current,
		'startDate': @->startDate,
		'endDate': @->endDate,
		'hero': @->hero ${asset},
		'heroImage': @->heroImage ${asset},
		'image': @->image ${asset}
	},
	seo ${seo}
}`;

module.exports.projectspage = groq`*[
	_type == 'projectspage' &&
	!(_id in path("drafts.**"))
][0] {
	featuredProjectsTitle,
	featuredProjects [] {
		_key,
		'linkType': @->_type,
		"type": @->type->type,
		"heroImage": @->heroImage.asset->url,
		"artist": @->artist,
		"title": @->title,
		'tileTitle': @->tileTitle,
		"slug": @->slug.current
	},
	listTitle,
	list [] {
		_key,
		'linkType': @->_type,
		"type": @->type->type,
		"heroImage": @->heroImage.asset->url,
		"artist": @->artist,
		"title": @->title,
		'tileTitle': @->tileTitle,
		"slug": @->slug.current
	},
	upcomingListTitle,
	upcomingList [] {
		_key,
		'linkType': @->_type,
		"type": @->type->type,
		"heroImage": @->heroImage.asset->url,
		"artist": @->artist,
		"title": @->title,
		'tileTitle': @->tileTitle,
		"slug": @->slug.current
	},
	pastListTitle,
	pastList [] {
		_key,
		'linkType': @->_type,
		"type": @->type->type,
		"heroImage": @->heroImage.asset->url,
		"artist": @->artist,
		"title": @->title,
		'tileTitle': @->tileTitle,
		"slug": @->slug.current
	},
}`;
module.exports.draftProjectspage = draftId => groq`*[_type == 'projectspage' && _id == '${draftId}'][0]{
	featuredProjectsTitle,
	featuredProjects [] {
		_key,
		'linkType': @->_type,
		"type": @->type->type,
		"heroImage": @->heroImage.asset->url,
		"artist": @->artist,
		"title": @->title,
		'tileTitle': @->tileTitle,
		"slug": @->slug.current
	},
	listTitle,
	list [] {
		_key,
		'linkType': @->_type,
		"type": @->type->type,
		"heroImage": @->heroImage.asset->url,
		"artist": @->artist,
		"title": @->title,
		'tileTitle': @->tileTitle,
		"slug": @->slug.current
	},
	upcomingListTitle,
	upcomingList [] {
		_key,
		'linkType': @->_type,
		"type": @->type->type,
		"heroImage": @->heroImage.asset->url,
		"artist": @->artist,
		"title": @->title,
		'tileTitle': @->tileTitle,
		"slug": @->slug.current
	},
	pastListTitle,
	pastList [] {
		_key,
		'linkType': @->_type,
		"type": @->type->type,
		"heroImage": @->heroImage.asset->url,
		"artist": @->artist,
		"title": @->title,
		'tileTitle': @->tileTitle,
		"slug": @->slug.current
	},
}`;

//
// === Artists ===
//

module.exports.artistsPage = groq`*[
	_type == 'artistspage' &&
	!(_id in path("drafts.**"))
][0]{
	featuredArtistsTitle,
 	artistsHeaderTitle,
	artistsHeaderDesc  ${richText},
  	featuredArtists[] {
		_key,
		_type,
		thumbnail ${asset},
		'title': @->title,
		'slug': @->slug.current,
		'customTitle': title,
		_type == 'featuredArtist' => {
			'title': artist->artist,
			'slug': artist->slug.current,
		},
		_type == 'featuredAnatomy' => {
			'title': anatomy->title,
			'slug': anatomy->slug.current,
		},

		_type == 'featuredProject' => {
			'title': project->artist,
			'slug': project->slug.current,
		},

		_type == 'featuredExhibition' => {
			'title': exhibition->artist,
			'slug': exhibition->slug.current,
		},

		_type == 'featuredSite' => {
			'title': site->title,
			'slug': site->slug.current,
		},

		'hero': @->hero ${asset},
		'heroImage': @->heroImage ${asset},
		'image': @->image ${asset},
		'artist': @->artist,
	},
	listTitle,
 	artistsListDesc  ${richText},
	list[] {
		_key,
		_type,
		thumbnail ${asset},
		'title': @->title,
		'slug': @->slug.current,
		'customTitle': title,
		_type == 'featuredArtist' => {
			'title': artist->artist,
			'slug': artist->slug.current,
		},
		_type == 'featuredAnatomy' => {
			'title': anatomy->title,
			'slug': anatomy->slug.current,
		},

		_type == 'featuredProject' => {
			'title': project->artist,
			'slug': project->slug.current,
		},

		_type == 'featuredExhibition' => {
			'title': exhibition->artist,
			'slug': exhibition->slug.current,
		},

		_type == 'featuredSite' => {
			'title': site->title,
			'slug': site->slug.current,
		},

		'hero': @->hero ${asset},
		'heroImage': @->heroImage ${asset},
		'image': @->image ${asset},
		'artist': @->artist,
	},
	seo ${seo}
}`;

module.exports.draftArtistsPage = draftId => groq`*[_type == 'artistspage' && _id == '${draftId}'][0]{
	featuredArtistsTitle,
 	artistsHeaderTitle,
	artistsHeaderDesc  ${richText},
  	featuredArtists[] {
		_key,
		_type,
		thumbnail ${asset},
		'hero': @->hero ${asset},
		'heroImage': @->heroImage ${asset},
		'image': @->image ${asset},
		'artist': @->artist,
		'title': @->title,
		'slug': @->slug.current,
		'anatomyTitle': anatomy->title,
		'anatomySlug': anatomy->slug.current,
		'projectTitle': project->artist,
		'projectSlug': project->slug.current,
		'exhibitionTitle': exhibition->artist,
		'exhibitionSlug': exhibition->slug.current,
		'artistSlug': artist->slug.current,
		'artistName': artist->artist,
		'siteSlug': site->slug.current
	},
	listTitle,
 	artistsListDesc  ${richText},
	list[] {
		_key,
		_type,
		thumbnail ${asset},
		'hero': @->hero ${asset},
		'heroImage': @->heroImage ${asset},
		'image': @->image ${asset},
		'artist': @->artist,
		'title': @->title,
		'tileTitle': @->tileTitle,
		'slug': @->slug.current,
		'anatomyTitle': anatomy->title,
		'anatomySlug': anatomy->slug.current,
		'projectTitle': project->artist,
		'projectSlug': project->slug.current,
		'exhibitionTitle': exhibition->artist,
		'exhibitionSlug': exhibition->slug.current,
		'artistSlug': artist->slug.current,
		'artistName': artist->artist,
		'siteSlug': site->slug.current
	},
	seo ${seo}
}`;

module.exports.artists = groq`*[_type == 'artist' && !(_id in path('drafts.**'))][] | order(priority desc, _createdAt desc) {
	'id': _id,
	'slug': slug.current,
	'title': artist,
	location,
	'hero': image ${asset},
	'heroGallery': profileImageGallery[] ${asset},
	bio {
		bioIntroImage {
			title,
			'url': image.asset->url
		},
		bioIntro ${richText},
		bioCV ${file},
		bioArtwork {
			title,
			portrait,
			description ${richText},
			artwork ${asset}
		},
		bioQuote ${pullQuote},
	},
	selectedWork[] {
		_key,
		_type,
		_type == 'selectedArtworkSingle' => {
			title,
			portrait,
			description ${richText},
			artwork ${asset}
		},
		_type == 'selectedArtworkDouble' => {
			imageOne ${artworkAsset},
			imageTwo ${artworkAsset},
		}
	},
	featuredExhibitionsTitle,
	exhibitions []-> {
		_id,
		_type,
		'linkType': _type,
		title,
		tileTitle,
		subtitle,
		artist,
		'slug': slug.current,
		image ${asset},
		hero ${asset},
		heroImage ${asset},
		startDate,
		endDate,
	},
	pressTitle,
	press [] {
		_key,
		thumbnail ${asset},
		title,
		publication,
		year,
		url,
		openInNewWindow
	},
	inquire ${email},
	seo ${seo}
}`;

module.exports.draftArtist = draftId => groq`*[_type == 'artist' && _id == '${draftId}'][0]{
	'slug': slug.current,
	'title': artist,
	location,
	'hero': image ${asset},
	'heroGallery': profileImageGallery[] ${asset},
	bio {
		bioIntroImage {
			title,
			'url': image.asset->url
		},
		bioIntro ${richText},
		bioCV ${file},
		bioArtwork {
			title,
			portrait,
			description ${richText},
			artwork ${asset}
		},
		bioQuote ${pullQuote},
	},
	selectedWork[] {
		_key,
		_type,
		_type == 'selectedArtworkSingle' => {
			title,
			portrait,
			description ${richText},
			artwork ${asset}
		},
		_type == 'selectedArtworkDouble' => {
			imageOne ${artworkAsset},
			imageTwo ${artworkAsset},
		}
	},
	featuredExhibitionsTitle,
	exhibitions []-> {
		_id,
		_type,
		'linkType': _type,
		title,
		tileTitle,
		subtitle,
		artist,
		'slug': slug.current,
		image ${asset},
		hero ${asset},
		heroImage ${asset},
		startDate,
		endDate,
	},
	pressTitle,
	press [] {
		_key,
		thumbnail ${asset},
		title,
		publication,
		year,
		url,
		openInNewWindow
	},
	inquire ${email},
	seo ${seo}
}`;

//
// === News Page ===
//

module.exports.newspage = groq`*[_type == 'newspage'][0]{
	title,
	list[] {
		_key,
		'thumbnail': @->thumbnail.asset->url,
		'headline': @->headline,
		'source': @->source,
		'date': @->date,
		'url': @->url
	}
}`;

module.exports.draftNewsPage = draftId => groq`*[_type == 'newspage' && _id == '${draftId}'][0]{
	title,
	list[] {
		_key,
		'thumbnail': @->thumbnail.asset->url,
		'headline': @->headline,
		'source': @->source,
		'date': @->date,
		'url': @->url
	}
}`;

//
// === Alliance Page ===
//

module.exports.alliancepage = groq`*[_type == 'alliancepage'][0] {
	featuredAllianceTitle,
	featuredAlliance-> {
		"slug": slug.current,
		title,
		artist,
		startDate,
		endDate,
		heroImage ${asset},
	},
	description ${richText},
	howItWorks[],
	pastAllianceTitle,
	pastAlliance[] {
		_key,
		"slug": @->slug.current,
		'title': @->title,
		'artist': @->artist,
		'startDate': @->startDate,
		'endDate': @->endDate,
		'heroImage': @->heroImage ${asset},
	},
  	seo ${seo},
}`;

module.exports.draftAlliancepage = draftId => groq`*[_type == 'alliancepage' && _id == '${draftId}'][0] {
	featuredAllianceTitle,
	featuredAlliance-> {
		"slug": slug.current,
		title,
		artist,
		startDate,
		endDate,
		heroImage ${asset},
	},
	description ${richText},
	howItWorks[],
	pastAllianceTitle,
	pastAlliance[] {
		_key,
		"slug": @->slug.current,
		'title': @->title,
		'artist': @->artist,
		'startDate': @->startDate,
		'endDate': @->endDate,
		'heroImage': @->heroImage ${asset},
	},
  	seo ${seo},
}`;

module.exports.alliances = groq`*[_type == 'alliance' && !(_id in path('drafts.**'))][] | order(priority desc, _createdAt desc) {
	'id': _id,
	'slug': slug.current,
	title,
	artist,
	startDate,
	endDate,
	heroImage ${asset},
	description ${richText},
	artistInfo {
		artistBio ${richText},
		artistImage ${asset},
	},
	content[] {
		_key,
		_type,
		_type == 'artworkImageSingle' => {
			title,
			portrait,
			description ${richText},
			artwork ${asset}
		},
		_type == 'artworkImageDouble' => {
			imageOne ${artworkAsset},
			imageTwo ${artworkAsset},
		},
		_type == 'pullQuote' => ${pullQuote},
		_type == 'externalVideo' => ${externalVideo},
	},
	charity {
		body ${richText},
		images [] ${asset},
		title,
		url,
		pullQuote ${pullQuote}
	},
	sheetId,
	minimumBid,
	estimatedValue,
	postSubmit ${richText},
	terms ${richText},
	bids [] {
		_key,
		bid,
		date
	},
	seo ${seo}
}`;

module.exports.allianceById = allianceId => groq`*[_type == 'alliance' && _id == '${allianceId}'][0] {
	'id': _id,
	'slug': slug.current,
	title,
	artist,
	startDate,
	endDate,
	heroImage ${asset},
	description ${richText},
	artistInfo {
		artistBio ${richText},
		artistImage ${asset},
	},
	content[] {
		_key,
		_type,
		_type == 'artworkImageSingle' => {
			title,
			portrait,
			description ${richText},
			artwork ${asset}
		},
		_type == 'artworkImageDouble' => {
			imageOne ${artworkAsset},
			imageTwo ${artworkAsset},
		},
		_type == 'pullQuote' => ${pullQuote},
		_type == 'externalVideo' => ${externalVideo},
	},
	charity {
		body ${richText},
		images [] ${asset},
		title,
		url,
		pullQuote ${pullQuote}
	},
	sheetId,
	minimumBid,
	estimatedValue,
	postSubmit ${richText},
	terms ${richText},
	bids [] {
		_key,
		bid,
		date
	},
	seo ${seo}
}`;

module.exports.draftAlliance = draftId => groq`*[_type == 'alliance' && _id == '${draftId}'][0] {
	'id': _id,
	'slug': slug.current,
	title,
	artist,
	startDate,
	endDate,
	heroImage ${asset},
	description ${richText},
	artistInfo {
		artistBio ${richText},
		artistImage ${asset},
	},
	content[] {
		_key,
		_type,
		_type == 'artworkImageSingle' => {
			title,
			portrait,
			description ${richText},
			artwork ${asset}
		},
		_type == 'artworkImageDouble' => {
			imageOne ${artworkAsset},
			imageTwo ${artworkAsset},
		},
		_type == 'pullQuote' => ${pullQuote},
		_type == 'externalVideo' => ${externalVideo},
	},
	charity {
		body ${richText},
		images [] ${asset},
		title,
		url,
		pullQuote ${pullQuote}
	},
	sheetId,
	minimumBid,
	postSubmit ${richText},
	terms ${richText},
	bids [] {
		_key,
		bid,
		date
	},
	seo ${seo}
}`;

//
// === Products ===
//

module.exports.shopPage = groq`*[_type == 'productspage' && !(_id in path('drafts.**'))][0] {
	title,
	list[] {
		_key,
		'slug': @->slug.current,
		'title': @->title,
		'vendor': @->vendor,
		'cents': @->cents,
		'thumbnail': @->images[0] ${asset}
	},
	seo ${seo}
}`;

module.exports.draftShopPage = draftId => groq`*[_type == 'productspage' && _id == '${draftId}'][0] {
	title,
	list[] {
		_key,
		'slug': @->slug.current,
		'title': @->title,
		'vendor': @->vendor,
		'cents': @->cents,
		'thumbnail': @->images[0] ${asset}
	},
	seo ${seo}
}`;

module.exports.products = groq`*[_type == 'product' && !(_id in path('drafts.**'))][] {
	'slug': slug.current,
	title,
	vendor,
	cents,
	productId,
	variantId,
	images[] ${asset},
	description ${richText},
	details [] {
		_key,
		title,
		value
	},
	seo ${seo}
}`;
module.exports.draftProduct = draftId => groq`*[_type == 'product' && _id == '${draftId}'][0] {
	'slug': slug.current,
	title,
	vendor,
	cents,
	productId,
	variantId,
	images[] ${asset},
	description ${richText},
	details [] {
		_key,
		title,
		value
	},
	seo ${seo}
}`;


//
// === Redirects ===
//

module.exports.redirects = groq`*[_type == 'redirect']{
	fromPath,
	toPath,
	statusCode
}`;

module.exports.header = groq`*[_type == 'header' ][0]{
	desktopMenu [] {
		_key,
		_type,
		openInNewWindow,
		title,
		url,
	},
	mobileMenu [] {
		_key,
		_type,
		openInNewWindow,
		title,
		url,
	}
}`;
